<div class="main">
  @if (selectedIndex === 0) {
    <div class="left-hlf">
      <div class="logo">
        <img src="./assets/citadel-logo.svg">
      </div>
      <div class="id-header">
        <div class="welcome">
          <p class="dark">Welcome,</p>
          <p class="gold">let's get you registered.</p>
        </div>
        <h4 class="subheader">Your financial future, at your fingertips.</h4>
        <p class="paragraph">
          With one click you will have a bird's-eye view of your investments, making it easier, simpler, and faster.
        </p>
      </div>
    </div>
  }

  <div class="right-hlf">
    <div class="right-hlf-header">
      @if (selectedIndex > 0) {
        <img src="./assets/citadel-logo.svg" class="logo">
      }
      <citadel-wizard-steps [steps]="steps" [selectedIndex]="selectedIndex"></citadel-wizard-steps>
    </div>
    <div class="content">
      <ng-container *ngTemplateOutlet="currentTemplate || identification"></ng-container>
    </div>
  </div>
</div>

<ng-template #identification>
  <form [formGroup]="identityForm" (ngSubmit)="goToPassword()">
    <citadel-form-section>
      <div class="header-strip mx-32 mt-32 mb-16">
        <div class="formHeader">Complete the following details</div>
      </div>

      <citadel-form-element prompt="ID/Passport number" class="mx-32">
        <citadel-input [formControlName]="'idNumber'" *citadelValidate="identityForm.controls['idNumber']" (input)="updateIdentification($event, 'id')"></citadel-input>
      </citadel-form-element>
      @if (identityFormSubmitted && idForm.idNumber.invalid  &&  idForm.idNumber.errors.required) {
        <div class="invalid-feedback mx-32">
          <small>ID/Passport number is required</small>
        </div>
      }

      <citadel-form-element prompt="Email" class="mx-32">
        <citadel-input [formControlName]="'email'" *citadelValidate="identityForm.controls['email']"
        (input)="updateIdentification($event, 'email')" placeholder="example@example.com"></citadel-input>
      </citadel-form-element>
      @if (identityFormSubmitted && idForm.email.invalid && idForm.email.errors.required) {
        <div
          class="invalid-feedback mx-32">
          <small>Email is required</small>
        </div>
      }

      <citadel-form-element prompt="Mobile number" class="mx-32">
        <citadel-phone-number-input [data]="countries" [formControlName]="'phoneNumber'" *citadelValidate="identityForm.controls['phoneNumber']" />
      </citadel-form-element>
      @if (identityFormSubmitted && idForm.phoneNumber.invalid  && idForm.phoneNumber.errors.required) {
        <div class="invalid-feedback mx-32">
          <small>Mobile number is required</small>
        </div>
      }

      <citadel-form-element class="mx-32">
        <citadel-checkbox [formControlName]="'termsAndConditions'">


          <span class="termsCls">
            I agree with the
            <a
              href="https://www.citadel.co.za/terms-and-conditions/" target="_blank" class="teal">Terms and
            Conditions</a>
          </span>


        </citadel-checkbox>
      </citadel-form-element>
      @if (identityFormSubmitted && idForm.termsAndConditions.invalid  && idForm.termsAndConditions.errors) {
        <div class="invalid-feedback mx-32">
          <small>Please agree to the terms and conditions before you continue.</small>
        </div>
      }


      <citadel-form-element class="mx-32 mb-32 ">
        <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="goToPassword()" [title]="'Next: Password'"
        [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
      </citadel-form-element>
      <div class="mx-32 mb-32">
        <span> Already have a profile?</span>
        <a [routerLink]="['/sign-in']" class="teal">&nbsp;Sign in here</a>
      </div>


    </citadel-form-section>
  </form>
</ng-template>


<ng-template #password>
  <form [formGroup]="passwordForm" (ngSubmit)="goToSendOTP()">
    <citadel-form-section class="card">
      <div class="header-strip mx-32 mt-32 mb-16">
        <div class="formHeader ">Create a strong password for your profile.</div>
      </div>

      <citadel-form-element prompt="Password" class="mx-32">
        <citadel-input type="password"  CitadelFormControlStyle="squared" isPasswordVisible="true"
          [formControlName]="'password'"  *citadelValidate="passwordForm.controls['password']"
        (input)="updatePassword($event, 'password')" placeholder="Enter password"></citadel-input>
      </citadel-form-element>
      <div class="strength mt-12 mx-32">
        <ul class="strengthBar">
          <li class="point" [ngStyle]="{ 'background-color': bar0 }"></li>
          <li class="point" [ngStyle]="{ 'background-color': bar1 }"></li>
          <li class="point" [ngStyle]="{ 'background-color': bar2 }"></li>
        </ul>
        <p class="bold font-11" [ngStyle]="{ 'color': scoreColor }">{{passwordStrength}}</p>
      </div>
      @if (passwordFormSubmitted && pswForm.password.invalid  &&  pswForm.password.errors.required) {
        <div class="invalid-feedback mx-32">
          <small>Password is required</small>
        </div>
      }
      <citadel-form-element prompt="Confirm password" class="mx-32 -mt-14">
        <citadel-input type="password" formControlName="confirmPassword" *citadelValidate="passwordForm.controls['confirmPassword']"  placeholder="Re-enter password"
        (input)="checkInput($event)"></citadel-input>
      </citadel-form-element>
      @if (!passwordMatch && !isConfirmPasswordPristine) {
        <div class="invalid-feedback mx-32">
          <small>  Passwords do not match</small>
        </div>
      }
      @if (passwordMatch && !isConfirmPasswordPristine) {
        <div class="match-txt">
          Passwords match
        </div>
      }

      <div class="password-errors mt-24 mx-32">
        <p class="bold">Password must:</p>

        <ul class="unstyled">

          <li [class.default-text]="hasMinLength === null" [class.error-text]="!hasMinLength"
            [class.valid-text]="hasMinLength" [ngClass]="{'error': !hasMinLength, 'success': hasMinLength}">
            @if (switchBullet) {
              <span class="bullet-icons">
                @if (!hasMinLength) {
                  <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                }
                @if (hasMinLength ) {
                  <citadel-icon icon="check-circle" [color]="'status-success'"></citadel-icon>
                }
              </span>
            }
            <span class="desc">Have at least 8 characters</span>
          </li>


          <li [class.default-text]="hasUpperAndLower === null" [class.error-text]="!hasUpperAndLower"
            [class.valid-text]="hasUpperAndLower" [ngClass]="{'error': !hasUpperAndLower, 'success': hasUpperAndLower}">
            @if (switchBullet) {
              <span class="bullet-icons">
                @if (!hasUpperAndLower) {
                  <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                }
                @if (hasUpperAndLower) {
                  <citadel-icon icon="check-circle"
                  [color]="'status-success'"></citadel-icon>
                }
              </span>
            }
            <span class="desc">Contain both lowercase and uppercase letters</span>
          </li>


          <li [class.default-text]="hasNumber === null" [class.error-text]="!hasNumber" [class.valid-text]="hasNumber"
            [ngClass]="{'error': !hasNumber, 'success': hasNumber}">
            @if (switchBullet) {
              <span class="bullet-icons">
                @if (!hasNumber) {
                  <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                }
                @if (hasNumber) {
                  <citadel-icon icon="check-circle" [color]="'status-success'"></citadel-icon>
                }
              </span>
            }
            <span class="desc">Contain at least one numerical character</span>
          </li>


          <li [class.default-text]="hasSymbol === null" [class.error-text]="!hasSymbol" [class.valid-text]="hasSymbol"
            [ngClass]="{'error': !hasSymbol, 'success': hasSymbol}">
            @if (switchBullet) {
              <span class="bullet-icons">
                @if (!hasSymbol) {
                  <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                }
                @if (hasSymbol) {
                  <citadel-icon icon="check-circle" [color]="'status-success'"></citadel-icon>
                }
              </span>
            }
            <span class="desc">Contain at least one special character</span>
          </li>


        </ul>
      </div>
      <citadel-form-element class="mx-32  mb-32">
        <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="goToSendOTP()" [title]="'Next: One-time-PIN'"
        [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
      </citadel-form-element>

    </citadel-form-section>

  </form>
</ng-template>


<ng-template #otpchannel>
  <citadel-form-section class="card">
    <div class="header-strip mx-32 mt-32 mb-48">
      <div class="formHeader">How would you like to receive your one-time-PIN (OTP)?</div>
      <div class="additional-security d-none-sm">This is required for additional security.</div>
    </div>
    <citadel-radio name="otpTypeLabels" [size]="24" [items]="otpTypeLabels" [(ngModel)]="otpType" (change)="onOtpChannelChange($event)"
    (keyup)="handleKeyUp($event, 'goToEnterOTP')" class="mx-32"></citadel-radio>
    @if (showChannelRequiredMsg) {
      <div class="invalid-feedback mx-32">
        <small>Please select an option before you continue</small>
      </div>
    }
    <citadel-form-element class="mx-32 mb-32">
      <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="goToEnterOTP()" [title]="'Send OTP'"
      [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
    </citadel-form-element>
  </citadel-form-section>
</ng-template>


<ng-template #enterotp>
  <citadel-form-section class="card">
    <div class="header-strip mx-32 mt-32 mb-16">
      <!-- <div class="formHeader">Check your
      {{otpTypeLabel}} for an OTP (one-time-PIN) and enter it below.</div> -->


      @if(this.otpType === 'SMS'){
        <div class="formHeader">Check your phone messaging app for an OTP (one-time-PIN) and enter it below.</div>
      }@else{
        <div class="formHeader">Check your WhatsApp for an OTP (one-time-PIN) and enter it below.</div>
      }


      <p class="additional-security">This is required for additional security.</p>
    </div>
    <citadel-form-element class="mx-32">
      <citadel-otp-input (inputChange)="updateOtp($event)" #otpInput
        (keyup)="handleKeyUp($event, 'completeRegistration')" />
    </citadel-form-element>

    @if (showPinRequiredMsg) {
      <div class="invalid-feedback mx-32">
        <small>A 4-digit OTP is required</small>
      </div>
    }
    <citadel-form-element class="mx-32 mb-32 ">
      <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="completeRegistration()"
      [title]="'Complete registration'" [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
    </citadel-form-element>
    <div class="mx-32 mb-32">
      <span (click)="resendOTP()" class="no-otp Didnt-receive-the-OTP">Didn't receive the OTP?</span>
    </div>
  </citadel-form-section>



</ng-template>


<ng-template #completeregistration>

  <div class="success-msg">
    <div class="icon">
      <citadel-icon [icon]="'check-circle'" [size]="64" [color]="'status-signed'"></citadel-icon>
    </div>

    <h2 class="mt-24">Your profile has been registered successfully.</h2>
    <p class="text-light">Give use a few moments while we sign you in.</p>
  </div>
</ng-template>