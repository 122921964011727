import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ISignOutResult } from '../../models/sign-out-result.model';

@Component({
  selector: 'app-sign-out-page',
  templateUrl: './sign-out-page.component.html',
  styleUrls: ['./sign-out-page.component.scss']
})
export class SignOutPageComponent implements OnInit {
  // returnUrl: string = '';
  // routingState: { [k: string]: any; };
  logoutId: string;
  idp: string;
  constructor(private http: HttpClient, private router: Router, route: ActivatedRoute, private location: Location) {
    this.logoutId = route.snapshot.queryParams['logoutId'];
    this.idp = localStorage ? localStorage.getItem('Citadel.Auth.Service.AccountType') : 'Email';
  }
  ngOnInit() {
    this.signOut();
  }
  signOut() {
    console.log(`Sign-out request initiated for logoutId=${this.logoutId} & idp=${this.idp}`);
    this.http.get<ISignOutResult>(`/api/auth/signout?logoutId=${this.logoutId}&idp=${this.idp}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true, //????????????
    }).subscribe(res => {
      // console.log('Signed out', res);      
      // {
      //   showSignoutPrompt: false, 
      //   clientName: "Citazen Launcher", 
      //   postLogoutRedirectUri: "https://localhost:4200/signed-out", 
      //   signOutIFrameUrl: "https://localhost:44365/connect/endsession/callbac…CEfinEMu3VW8R6HOVkHc745MI2dZiasgqKDZxuibRv4maqbNM", 
      //   logoutId: "CfDJ8ArQrJoTmFVPvVMwyRjLVS4mUo1_ZkoCTq8ZqxXhJvOGMn…NhXhveRwerneGXR1Hg6NMJoxjbivzaIn3bK7MOptE97iORV9w"
      // }
      console.log('Sign-out returned from server:', res.postLogoutRedirectUri, res.signOutIFrameUrl);
      if (res.postLogoutRedirectUri && res.signOutIFrameUrl) {
        window.location.href = `${res.postLogoutRedirectUri}`;//?signOutIFrameUrl=${res.signOutIFrameUrl}`;
      } else if (res.authType > 0) {
        window.location.href = `/signout-oidc?logoutId=${this.logoutId}`;
      }
      else {
        const postLogoutUri = res.postLogoutRedirectUri || document.referrer;
        window.location.href = `${postLogoutUri}`;//?signOutIFrameUrl=${res.signOutIFrameUrl}`;
      }
    });
  }
}
