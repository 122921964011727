import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private sub: ReplaySubject<string>;
  paragraph: Observable<string>;

  mszText = 'Welcome to our secure digital client platform, Secure Portal.'
    + '<br /><br />'
    + 'The platform places your financial future at your fingertips. With one click you will have a bird\'s-eye view of your investments, making it easier, simpler, and faster.'
    + '<br /><br />'
    + 'We are a phone call away if you need any technical support. Contact Secure Portal Support on 086 110 0628 between 08:00 and 16:30 on weekdays, or email <a href="mailto:support@secureportal.co.za">support@secureportal.co.za</a>.';

  citazenText = '"Longevity in business is about being able to reinvent yourself or invent the future."<br />~Satya Nadella, CEO of Microsoft'
    + '<br /><br />'
    + 'Welcome to Citazen. A cloud-based system to manage the different stages of your client\'s life cycle. The goal of this platform is to simplify. Together, we are part of a remarkable digital journey.';

  constructor() {
    this.sub = new ReplaySubject<string>(1);
    this.paragraph = this.sub.asObservable();
  }

  setParagraph(application: string) {
    setTimeout(() => {
      if (application === 'MSZ') {
        this.sub.next(this.mszText);
      }
      else {
        this.sub.next(this.citazenText);
      }
    });
  }
}
