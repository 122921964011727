import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SignInPageComponent } from './pages/sign-in-page/sign-in-page.component';
import { PasswordEntryPageComponent } from './pages/password-entry-page/password-entry-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { InvalidEmailPageComponent } from './pages/invalid-email-page/invalid-email-page.component';
import { SignInRedirectorComponent } from './pages/sign-in-redirector/sign-in-redirector.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { SignOutPageComponent } from './pages/sign-out-page/sign-out-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';


const routes: Routes = [

  { path: 'sign-in-redirect', component: SignInRedirectorComponent },
  { path: 'sign-in', component: SignInPageComponent, data: { controlStyle: 'squared', colorScheme: { background: 'light', sections: 'white', controls: 'light' } } },
  { path: 'sign-out', component: SignOutPageComponent },
  { path: 'signin-oidc', component: SignOutPageComponent },
  { path: 'password', component: PasswordEntryPageComponent },
  { path: 'register', component: RegistrationPageComponent, data: { controlStyle: 'squared', colorScheme: { background: 'light', sections: 'white', controls: 'light' } } },
  { path: 'forgot-password', component: ForgotPasswordPageComponent, data: { controlStyle: 'squared', colorScheme: { background: 'light', sections: 'white', controls: 'light' } } },
  { path: 'invalid-email', component: InvalidEmailPageComponent },
  { path: 'error', component: ErrorPageComponent },
  {
    path: 'account', children: [
      { path: 'login', component: SignInRedirectorComponent },
    ]
  },
  { path: '', component: HomeComponent, pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
