import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../services/registration-service.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CitadelAutoCompleteComponent } from '@citadel/ds-controls';
import { BehaviorSubject } from 'rxjs';
import { SignInService } from '../../services/sign-in-service';
import { CitadelNotificationService } from '@citadel/ds-core';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  @ViewChild('otpchannel', { static: false }) otpchannel: TemplateRef<any>;
  @ViewChild('enterotp', { static: false }) enterotp: TemplateRef<any>;
  @ViewChild('password', { static: false }) password: TemplateRef<any>;
  @ViewChild('completeregistration', { static: false }) completeregistration: TemplateRef<any>;
  @ViewChild('country', { static: false }) country: CitadelAutoCompleteComponent;
  @ViewChild('userEntryTemplate', { static: false }) userEntryTemplate: TemplateRef<any>;
  @ViewChild('emailSentTemplate', { static: false }) emailSentTemplate: TemplateRef<any>;
  @ViewChild('resetTemplate', { static: false }) resetTemplate: TemplateRef<any>;
  @ViewChild('otpTemplate', { static: false }) otpTemplate: TemplateRef<any>;

  selectedIndex = 0;
  steps: any = [
    { title: new BehaviorSubject<string>('New password') },
    { title: new BehaviorSubject<string>('One-time-PIN') }
  ]
  private editedPasswords = { 
    // CitadelInput doesn't update the form on input, but rather on change, so we need to keep track of the input value
    password: '', 
    confirmPassword: '' 
  };
  currentTemplate: TemplateRef<any>;
  passwordForm: FormGroup;
  identityForm: FormGroup;
  countries: any[] = [];
  extras: any;
  returnUrl: any;
  redirectUrl: any;
  appID: any;
  clientId: string;
  registerMessage: string;
  clientName: string;
  passwordMessage: string;
  passwordStrength: string;
  confirmPassword: string;
  currentNumber?: any;
  cellNumber: string = ''
  pwd: string;
  otpType: string;
  otpValue: string = '';
  score: number = 0;
  recaptchav3_token: string;
  termsAndConditions: boolean;
  registerError: boolean;
  otpSent: boolean;
  switchBullet: boolean;
  hasUpperAndLower: any = null;
  hasMinLength: any = null;
  hasNumber: any = null;
  hasSymbol: any = null;
  bar0: string;
  bar1: string;
  bar2: string;
  scoreColor: string;
  defaultErrorMessage: string = 'An error occurred. Please try again';

  otpTypeLabels = [
    { name: "SMS", value: "SMS" },
    { name: "WhatsApp", value: "WhatsApp" }
  ]
  passwordMatch: any;

  get otpTypeLabel(): string {
    return this.otpType === 'SMS' ? 'phone messaging app' : this.otpType === 'WhatsApp' ? 'WhatsApp' : '';
  }

  routingState: { [k: string]: any; };
  passwordDisabled = true;
  resetRequestId: string = undefined;
  otpDisabled = true;
  forgotPasswordForm: FormGroup;
  arePasswordsDirty: boolean = false;
  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private registration: RegistrationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: FormBuilder,
    private signInService: SignInService,
    private notify: CitadelNotificationService,
    private route: ActivatedRoute,

  ) {
    const nav = router.getCurrentNavigation() || { extras: { state: {} } };
    const extras = nav.extras || {};
    this.routingState = extras.state || {};
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['returnUrl']) {
        this.returnUrl = queryParams['returnUrl'];
      }
      if (queryParams['email']) {
        this.resetRequestId = queryParams['resetRequestId'];
        this.forgotPasswordForm.controls.emailAddress.setValue(queryParams['email']);
        this.returnUrl = queryParams['returnUrl'];
      }
      else {
        const params = this.routingState;
        if (params) {
          this.returnUrl = params['returnUrl'];
        }
      }
      const match = this.returnUrl.match(/redirect_uri=[^\&]+/);
      if (match && match.length > 0) {
        this.redirectUrl = match[0].replace('auth-callback', '').replace('redirect_uri=', '').replace(/%2F/g, '/').replace(/%3A/g, ':');
      }
      if (queryParams['appID']) {
        this.appID = queryParams['appID'];
        if (this.appID === 'Citadel.Launcher.Msz') {

        }
      }
    });

    this.forgotPasswordForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]]
    });
    this.setPasswordForm();
  }

  setPasswordForm(): void {
    this.passwordForm = new FormGroup({
      password: new FormControl('', { validators: Validators.required }),
      confirmPassword: new FormControl('', { validators: Validators.required }),
    });
  }

  checkInput() {
    this.passwordMatch = this.editedPasswords.password === this.editedPasswords.confirmPassword;
    this.arePasswordsDirty = this.passwordForm.controls.confirmPassword.dirty || this.passwordForm.controls.password.dirty;
  }

  updateValidity(e) {
    e.target.value = e.target.value.replace(/ /g, '');
    this.forgotPasswordForm.controls.emailAddress.patchValue(e.target.value);
  }

  beforeValidateEmail() {
    this.recaptchaV3Service.execute('validateEmail').subscribe({
      next: (token) => {
        this.recaptchav3_token = token;
        this.validateEmail();
      },
      error: _error => {
        this.notify.error('Error verifying the request (reCaptcha v3)');
      }
    });
  }

  validateEmail() {
    if (!this.forgotPasswordForm.value.emailAddress.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/)) {
      return;
    }

    this.http.post<string>(`/api/auth/forgotpassword`,
      { email: this.forgotPasswordForm.value.emailAddress, returnUrl: this.returnUrl, recaptchaToken: this.recaptchav3_token },
      { headers: { 'Content-Type': 'application/json' }, withCredentials: true, }).subscribe({
        next: res => {
          if (res.match(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)) {
            this.resetRequestId = res;
            this.currentTemplate = this.resetTemplate;
          }
          else {
            this.notify.error(res);
          }
        }, error: _error => {
          this.notify.error('An error occurred validating the email address');
          //window.location.href = this.credentials.returnUrl;
        }
      });
  }

  return() {
    window.location.href = this.redirectUrl;
  }

  updateIdentification(e, id): void {
    if (id === 'email') {
      e.target.value = e.target.value.replace(/ /g, '');
    }
  }

  updateOtp(e): void {
    this.otpValue = e;
  }

  updatePassword(e, password): void {
    this.checkPasswordStrength(e, password);
    this.editedPasswords.password = e.target.value;
    this.checkInput();
  }
  updateConfirmPassword(e): void {
    this.editedPasswords.confirmPassword = e.target.value;
    this.checkInput();
  }
  resendOTP(): void {
    this.otpSent = false;
    this.currentTemplate = this.otpchannel;
  }
  checkPasswordStrength(e, password): void {
    this.registration.validatePasswordStrength(password === 'password' ? e.target.value : this.pwd);
    this.passwordStrength = this.registration.passwordStrength;
    this.hasUpperAndLower = this.registration.hasUpperAndLower
    this.hasMinLength = this.registration.hasMinLength;
    this.hasNumber = this.registration.hasNumber;
    this.hasSymbol = this.registration.hasSymbol;
    this.scoreColor = this.registration.scoreColor;
    this.bar0 = this.registration.bar0;
    this.bar1 = this.registration.bar1;
    this.bar2 = this.registration.bar2;
    this.switchBullet = this.registration.switchBullet;
  }

  goToSendOTP() {

    if (this.passwordMatch) {

      if (this.hasUpperAndLower && this.hasMinLength && this.hasNumber && this.hasSymbol) {
        this.selectedIndex = 1;
        this.currentTemplate = this.otpchannel;
      } else {
        this.notify.error("Please choose a strong password by following our guidelines on the screen.");
      }
    } else {
      this.notify.error("Passwords do not match.");
    }
  }

  goToEnterOTP(): void {
    if (this.otpType) {
      this.sendOtp();
    } else {
      this.notify.error('Please select an option before you continue');
    }
  }
  resetPassword() {
    if (!this.otpValue || this.otpValue.length < 4) {
      this.notify.error('A 4-digit OTP is required');
    } else {
      this.redirectUrl = this.signInService.signInReturnUrl;
      this.selectedIndex = 1;
      this.http.post<string>(`/api/auth/resetpassword`,
        { email: this.signInService.passwordResetEmail, password: this.passwordForm.value.password, otp: parseInt(this.otpValue), resetRequestId: this.signInService.passwordResetRequestId, returnUrl: this.signInService.signInReturnUrl },
        { headers: { 'Content-Type': 'application/json' }, withCredentials: true, }).subscribe({
          next: res => {
            if (res) {
              this.notify.error(res);
            }
            else {
              this.notify.success('Please sign in again using your new password.', "Your password has been reset.");
              this.router.navigateByUrl('/sign-in', { state: { returnUrl: this.signInService.signInReturnUrl } })
            }
          }, error: _ => {
            this.notify.error(this.defaultErrorMessage);
            this.otpSent = false;
            this.currentTemplate = this.password;
            this.selectedIndex = 0;
          }
        });
    }
  }




  sendOtp(): void {
    if (!this.otpSent) {
      this.otpSent = true;
      if (this.otpType) {
        this.registration.sendResetOtp(this.otpType, this.signInService.passwordResetRequestId).subscribe({
          next: _ => {
            this.notify.success('OTP sent');
            this.currentTemplate = this.enterotp;
          }, error: error => {
            this.notify.error(error.message);
            this.currentTemplate = this.enterotp;
          }
        });
      } else {
        this.notify.error('Please select an option before you continue');
      }
    }
  }

  handleKeyUp(e, step) {
    if (e.keyCode === 13) {
      switch (step) {
        case "goToEnterOTP":
          this.goToEnterOTP();
          break;
        case "resetPassword":
          this.resetPassword();
          break;
      }
    }
  }


}
