import { Component, ElementRef, OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { CitadelDialogService, CitadelIconService, CitadelPageStyleService , CitadelSystemNotificationService, FooterAction, FooterActionList } from '@citadel/ds-controls';
import { ApplicationService } from './services/application-service.service';

import { CitadelConfigurationService, CitadelMaintenanceService, CitadelWebService } from '@citadel/ds-core';
import { Overlay } from '@angular/cdk/overlay';
import { first, map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  text: string;
  emailStr:string = "support@citadel.co.za";

  footerItems: FooterActionList = new FooterActionList();
  protected backgroundClass = 'white'; // alternate will be interpreted as white
  constructor(
    private web: CitadelWebService,
    private router: Router,
    private application: ApplicationService,
    private icons: CitadelIconService,
    private config: CitadelConfigurationService,
    colorService: CitadelPageStyleService,
    private systemNotification: CitadelSystemNotificationService,
    private overlay: Overlay, private element: ElementRef, private viewContainerRef: ViewContainerRef,
    private dialog: CitadelDialogService,
    private maintain: CitadelMaintenanceService) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        console.log('SERVER: Routing event: Start:', e.url);
      } else if (e instanceof NavigationEnd) {
        console.log('SERVER: Routing event: End:', e.url);
      }
    });
    this.systemNotification.get().subscribe(({ heading, text }) => this.showNotice(heading, text));
    application.paragraph.subscribe(res => { this.text = res });
    // this.maintain.getMaintenanceCallParameters = settings => this.maintenanceParams.getMaintenanceParams();
    this.config.getGlobalSettingsLoaded().pipe(
      first(),
      mergeMap(settings => maintain.getMaintenancePending().pipe(map(maintenanceEntry => ({ maintenanceEntry, rerouteAddress: settings.rerouteAddress })))))
      .subscribe(({maintenanceEntry, rerouteAddress}) => {
      if (maintenanceEntry) {
        const now = new Date();
        if (maintenanceEntry.endDate >= now && maintenanceEntry.startDate <= now && !!rerouteAddress) {
          window.location.href = rerouteAddress;
        } else if (maintenanceEntry.startDate >= now) {
          this.systemNotification.showNotice('System notice', maintenanceEntry.noticeMessage);
        }
      }
    });

    this.loadFooter();
    colorService.getColorScheme().subscribe(scheme => this.backgroundClass = scheme?.background || 'white');
  }
  showNotice(heading: string, text: string) {
    this.dialog.showConfirmation(text, heading, 'Got it', 'bell-ringing', () => {});
    // let overlayRef = this.overlay.create({
    //   disposeOnNavigation: false,
    //   hasBackdrop: false,
    //   scrollStrategy: this.overlay.scrollStrategies.reposition({ autoClose: false }),
    //   maxWidth: '320px',
    //   minWidth: '320px',
    //   positionStrategy: this.overlay.position().flexibleConnectedTo(this.element).withPositions([{
    //     originX: 'end',
    //     originY: 'bottom',
    //     overlayX: 'end',
    //     overlayY: 'bottom',
    //   }])
    // });
    // const popupComponentPortal = new TemplatePortal(this.dropdown, this.viewContainerRef);

    // overlayRef.attach(popupComponentPortal);


    // overlayRef.backdropClick().subscribe(() => {
    //   this.isOpen = false;
    //   this.fields.overlayRef.dispose();
    //   this.fields.overlayRef = null;
    // });
  }


  ngOnInit() {
    this.registerServices();+
    this.registerIcons();
  }


  loadFooter() {
    this.footerItems.logo = 'logo';
    this.footerItems.copyrightName = `© Citadel ${(new Date()).getFullYear()}`;
    this.footerItems.useLogo = true;


    let footerItem: FooterAction = new FooterAction();
    footerItem.url = 'https://www.citadel.co.za/privacy-notice/';
    footerItem.title = 'Privacy Notice';

    this.footerItems.actions.push(footerItem);

    footerItem = new FooterAction()
    footerItem.url = 'mailto:compliance@citadel.co.za';
    footerItem.title = 'Conflict of Interest';

    this.footerItems.actions.push(footerItem);

    footerItem = new FooterAction()
    footerItem.url = 'https://www.citadel.co.za/terms-and-conditions/';
    footerItem.title = 'Terms and Conditions';

    this.footerItems.actions.push(footerItem);
  }

  private registerIcons() {
    this.icons.addIconLibrary('', 'assets/secure-portal-icons.svg');
    this.icons.addIconLibrary('calc', 'assets/calculator-and-contact-icons.svg');
    this.icons.addIconLibrary('dash', 'assets/dashboard-icons.svg');
    this.icons.addIconLibrary('flag', 'assets/flag-icons.svg');
  }

  private registerServices() {
    this.web.addServicesFromConfig(this.config.getConfig(),
      { name: 'Citadel.Auth.User', serviceConfigName: 'Services:Citadel.Framework.Permission', controllerName: 'User' },
      { name: 'Citadel.Menu', serviceConfigName: 'Services:Citadel.Menu', controllerName: 'Menu' },
      { name: 'Citadel.Lookups', serviceConfigName: 'Services:Citadel.Lookups', controllerName: 'Lookup' },
      { name: 'Tyrus.Entity.Search', serviceConfigName: 'Services:Tyrus.Entity.Integration', controllerName: 'Client' },
      { name: 'Tyrus.Entity.Sharing', serviceConfigName: 'Services:Tyrus.Entity.Integration', controllerName: 'Staff' },
      { name: 'crm.Party', serviceConfigName: 'Services:Citadel.CRM.Service', controllerName: 'Party' },
      { name: 'PushNotifications', serviceConfigName: 'Services:Citadel.Notification.Service', controllerName: 'Push' },
    );
  }
  
}
