<div class="main">


  <div class="right-hlf">
    <div class="right-hlf-header">
      <img src="./assets/citadel-logo.svg" class="logo">
      <citadel-wizard-steps [steps]="steps" [selectedIndex]="selectedIndex"></citadel-wizard-steps>
    </div>
    <div class="content">
      <ng-container *ngTemplateOutlet="currentTemplate || password"></ng-container>
    </div>
  </div>


  <ng-template #password>
    <form [formGroup]="passwordForm" (ngSubmit)="goToSendOTP()">
      <citadel-form-section>
        <div class="header-strip mx-32 mt-32 mb-16">
          <div class="formHeader">Create a new password</div>
          <p class="additional-security">You'll use this and your email to sign in, in the future.</p>
        </div>

        <citadel-form-element prompt="Password" class="mx-32">
          <citadel-input type="password" CitadelFormControlStyle="squared" [formControlName]="'password'"
            *citadelValidate="passwordForm.controls['password']" (input)="updatePassword($event, 'password')"
          placeholder="Enter password" />
        </citadel-form-element>

        <div class="strength mt-12 mx-32">
          <ul class="strengthBar">
            <li class="point" [ngStyle]="{ 'background-color': bar0 }"></li>
            <li class="point" [ngStyle]="{ 'background-color': bar1 }"></li>
            <li class="point" [ngStyle]="{ 'background-color': bar2 }"></li>
          </ul>
          <p class="bold font-11" [ngStyle]="{ 'color': scoreColor }">{{passwordStrength}}</p>
        </div>

        <citadel-form-element prompt="Confirm password" class="mx-32 -mt-14">
          <citadel-input type="password" formControlName="confirmPassword" *citadelValidate="passwordForm.controls['confirmPassword']" 
          placeholder="Re-enter password" (input)="updateConfirmPassword($event)"/>
        </citadel-form-element>
        @if (!passwordMatch && arePasswordsDirty) {
          <div class="validationErr mx-32">
            <small>
              Passwords do not match
            </small>
          </div>
        }
        @if (passwordMatch && arePasswordsDirty) {
          <div class="match-txt">
            Passwords match
          </div>
        }

        <div class="password-errors mt-24 mx-32">
          <p class="bold">Password must:</p>

          <ul class="unstyled">

            <li [class.default-text]="hasMinLength === null" [class.error-text]="!hasMinLength"
              [class.valid-text]="hasMinLength" [ngClass]="{'error': !hasMinLength, 'success': hasMinLength}">
              @if (switchBullet) {
                <span class="bullet-icons">
                  @if (!hasMinLength) {
                    <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                  }
                  @if (hasMinLength ) {
                    <citadel-icon icon="check-circle" [color]="'status-success'"></citadel-icon>
                  }
                </span>
              }
              <span class="desc">Have at least 8 characters</span>
            </li>


            <li [class.default-text]="hasUpperAndLower === null" [class.error-text]="!hasUpperAndLower"
              [class.valid-text]="hasUpperAndLower"
              [ngClass]="{'error': !hasUpperAndLower, 'success': hasUpperAndLower}">
              @if (switchBullet) {
                <span class="bullet-icons">
                  @if (!hasUpperAndLower) {
                    <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                  }
                  @if (hasUpperAndLower) {
                    <citadel-icon icon="check-circle" [color]="'status-success'"></citadel-icon>
                  }
                </span>
              }
              <span class="desc">Contain both lowercase and uppercase letters</span>
            </li>


            <li [class.default-text]="hasNumber === null" [class.error-text]="!hasNumber" [class.valid-text]="hasNumber"
              [ngClass]="{'error': !hasNumber, 'success': hasNumber}">
              @if (switchBullet) {
                <span class="bullet-icons">
                  @if (!hasNumber) {
                    <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                  }
                  @if (hasNumber) {
                    <citadel-icon icon="check-circle" [color]="'status-success'"></citadel-icon>
                  }
                </span>
              }
              <span class="desc">Contain at least one numerical character</span>
            </li>


            <li [class.default-text]="hasSymbol === null" [class.error-text]="!hasSymbol" [class.valid-text]="hasSymbol"
              [ngClass]="{'error': !hasSymbol, 'success': hasSymbol}">
              @if (switchBullet) {
                <span class="bullet-icons">
                  @if (!hasSymbol) {
                    <citadel-icon icon="alert-triangle" [color]="'danger'"></citadel-icon>
                  }
                  @if (hasSymbol) {
                    <citadel-icon icon="check-circle" [color]="'status-success'"></citadel-icon>
                  }
                </span>
              }
              <span class="desc">Contain at least one special character</span>
            </li>


          </ul>
        </div>


        <citadel-form-element class="mx-32 mb-32 ">
          <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="goToSendOTP()" [title]="'Next: One-time-PIN'"
          [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
        </citadel-form-element>
      </citadel-form-section>

    </form>
  </ng-template>


  <ng-template #otpchannel>
    <citadel-form-section>
      <div class="header-strip mx-32 mt-32 mb-48">
        <div class="formHeader">To reset your password we need to send you an OTP. Select how you would like to receive
        it:</div>
      </div>
      <citadel-radio name="otpTypeLabels" [size]="24" [items]="otpTypeLabels" [(ngModel)]="otpType" (keyup)="handleKeyUp($event, 'goToEnterOTP')"
      class="mx-32"></citadel-radio>
      <citadel-form-element class="mx-32 mb-32">
        <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="goToEnterOTP()" [title]="'Send OTP'"
        [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
      </citadel-form-element>
    </citadel-form-section>
  </ng-template>


  <ng-template #enterotp>
    <citadel-form-section>
      <div class="header-strip mx-32 mt-32 mb-16">
        <div class="form-header-extra">Check your {{otpTypeLabel}} for an OTP (one-time-PIN) and enter it below.</div>
        <div class="additional-security">This is required for additional security.</div>
      </div>
      <citadel-form-element class="mx-32">
        <citadel-otp-input (inputChange)="updateOtp($event)" (keyup)="handleKeyUp($event, 'resetPassword')"/>
      </citadel-form-element>


      <citadel-form-element class="mx-32 mb-32 ">
        <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="resetPassword()" [title]="'Reset Password'"
        [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
      </citadel-form-element>
      <div class="mx-32 mb-32">
        <a (click)="resendOTP()" class="no-otp">Didn't receive the OTP?</a>
      </div>
    </citadel-form-section>
  </ng-template>

