<div class="main">
  @if (selectedIndex === 0) {
    <div class="left-hlf">
      <div class="logo">
        <img src="./assets/citadel-logo.svg">
      </div>
      <div class="id-header">
        <div class="welcome">
          <p class="dark">Welcome,</p>
          <p class="gold">let's sign you in.</p>
        </div>
      </div>
    </div>
  }

  <div class="right-hlf">
    <div class="right-hlf-header">
      @if (selectedIndex > 0) {
        <img src="./assets/citadel-logo.svg" class="logo">
      }
      <citadel-wizard-steps [steps]="steps" [selectedIndex]="selectedIndex"></citadel-wizard-steps>
    </div>
    <div class="content">
      <ng-container *ngTemplateOutlet="currentTemplate || emailstep"></ng-container>
    </div>
  </div>



  <ng-template #emailstep>
    <form [formGroup]="emailForm" (ngSubmit)="goToPassword()">
      <citadel-form-section>
        <div class="header-strip mx-32 mt-32 mb-16">
          <div class="formHeader">Enter your email address</div>
        </div>

        <citadel-form-element prompt="Username" class="mx-32" optional>
          <citadel-input [formControlName]="'email'" *citadelValidate="emailForm.controls['email']"
          (input)="updateIdentification($event, 'email')" placeholder="Enter email"></citadel-input>
        </citadel-form-element>
        @if (emailFormSubmitted && usernameForm.email.invalid && usernameForm.email.errors.required) {
          <div
            class="invalid-feedback mx-32">
            <small>Email is required</small>
          </div>
        }


        <citadel-form-element class="mx-32 mb-32 ">
          <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="goToPassword()" [title]="'Next: Password'"
          [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
        </citadel-form-element>
        <div class="mx-32 mb-32">
          <span> Don't have a profile?</span>
          <a [routerLink]="['/register']" class="teal">&nbsp;Register here</a>
        </div>

      </citadel-form-section>
    </form>
  </ng-template>



  <ng-template #password>
    <form [formGroup]="passwordForm" (ngSubmit)="goToSendOTP()">
      <citadel-form-section>
        <div class="header-strip mx-32 mt-32 mb-16">
          <div class="formHeader ">Enter your password</div>
        </div>

        <citadel-form-element prompt="Password" class="mx-32">
          <citadel-input type="password" CitadelFormControlStyle="squared" isPasswordVisible="true"
            [formControlName]="'password'" *citadelValidate="passwordForm.controls['password']"
            placeholder="Enter password"></citadel-input>
        </citadel-form-element>
        @if (passwordFormSubmitted && pswForm.password.invalid  &&  pswForm.password.errors.required) {
          <div
            class="invalid-feedback mx-32">
            <small>Password is required</small>
          </div>
        }
        <citadel-form-element class="mx-32 mb-32 ">
          <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="goToSendOTP()" [title]="'Next: One-time-PIN'"
          [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
        </citadel-form-element>
        <div class="mx-32 mb-32">
          <a (click)="beforeValidateEmail()" class="teal cursor-pointer">Forgot your password?</a>
        </div>
      </citadel-form-section>
    </form>
  </ng-template>

  <ng-template #otpchannel>
    <citadel-form-section>
      <div class="header-strip mx-32 mt-32 mb-48">
        <div class="formHeader"> Select how you would like to receive your OTP:</div>
      </div>
      <citadel-radio name="otpTypeLabels" [size]="24" [items]="otpTypeLabels" [(ngModel)]="otpType"
      (change)="onOtpChannelChange($event)" (keyup)="handleKeyUp($event, 'sendOtp')" class="mx-32"></citadel-radio>

      @if (showChannelRequiredMsg) {
        <div class="invalid-feedback mx-32">
          <small>Please select an option before you continue</small>
        </div>
      }
      <citadel-form-element class="mx-32 mb-32">
        <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="sendOtp()" [title]="'Send OTP'"
        [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
      </citadel-form-element>
    </citadel-form-section>
  </ng-template>


  <ng-template #enterotp>
    <citadel-form-section>
      <div class="header-strip mx-32 mt-32 mb-16">
        @if(this.otpType === 'SMS'){
          <div class="formHeader">Check your phone messaging app for an OTP (one-time-PIN) and enter it below.</div>
        }@else{
          <div class="formHeader">Check your WhatsApp for an OTP (one-time-PIN) and enter it below.</div>
        }

        <div class="additional-security ">This is required for additional security.</div>
      </div>
      <citadel-form-element class="mx-32">
        <citadel-otp-input (inputChange)="updateOtp($event)" (keyup)="handleKeyUp($event, 'signIn')" />
      </citadel-form-element>
      @if (showPinRequiredMsg) {
        <div class="invalid-feedback mx-32">
          <small>A 4-digit OTP is required</small>
        </div>
      }
      <citadel-form-element class="mx-32 mb-32 ">
        <citadel-button [isSubmit]="true" buttonStyle="primary" (click)="signIn()" [title]="'Sign in'"
        [icon]="{right:'chevron-right'}" buttonSize="large"></citadel-button>
      </citadel-form-element>
      <div class="mx-32 mb-32">
        <a (click)="resendOTP()" class="no-otp">{{resendOtpText}}</a>
      </div>
    </citadel-form-section>
  </ng-template>