import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    //const snapshot = this.route.snapshot;
    // console.log('SIGN-IN: Route parameters:', { paramMap: snapshot.paramMap, params: snapshot.params, queryParams: snapshot.queryParams });
    // window.location.href = 'https://www.mysecurezone.co.za/';
    //  window.location.href = 'register';
     window.location.href = 'sign-in';
    // window.location.href = 'forgot-password';


    //for testing purposes
    //----------------------------------------------------
    //let returnUrl = "https://www.mysecurezone.co.za/";

    // this.router.navigate(['..', 'sign-in'], { relativeTo: this.route, state: { 
    //   returnUrl,
    //   queryParams: {
    //     ReturnUrl: null,
    //   },
    //   queryParamsHandling: 'merge'
    // }});
    //----------------------------------------------------

  }

}
