import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sign-in-redirector',
  templateUrl: './sign-in-redirector.component.html',
  styleUrls: ['./sign-in-redirector.component.scss']
})
export class SignInRedirectorComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, ) { }

  ngOnInit() {
    const returnUrl = this.addAuthSegment(this.route.snapshot.queryParams['ReturnUrl'] || '');
    if (returnUrl) {
      this.router.navigate(['..', 'sign-in'], { relativeTo: this.route, state: { 
        returnUrl,
        queryParams: {
          ReturnUrl: null,
        },
        queryParamsHandling: 'merge'
      }});
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }
  private addAuthSegment(url: string) {
    // if (url.startsWith('/connect/authorize')) {
    //   return `/auth${url}`;
    // } else {
      return url;
    // }
  }
}
