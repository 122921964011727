<citadel-app-frame [isTestMode]="false" [showSidebar]="false">
  
  <ng-container app-content>
    <router-outlet />





  </ng-container>

  <ng-container app-footer>


    <div class="footer-banner">
      <div class="icon">
        <citadel-icon [icon]="'help-circle'" [size]="32" [color]="'alternate-1'"></citadel-icon>
      </div>
      <div>
        <p><strong>We are a phone call away if you need any technical support.</strong></p>
        <p>
          Contact Support on 086 110 0628 between 08:00 and 16:30 on weekdays, or email <span class="teal">{{emailStr}}</span>
        </p>
      </div>

    </div>
    <div class="footerMainCls">
      <div class="footerCls">
        <citadel-footer class="no-border" [footerItems]="footerItems" ></citadel-footer>
      </div>
 
    </div>
  </ng-container>

</citadel-app-frame>

<!-- <ng-template #systemNotification>
  <div><citadel-icon icon="bell" /><span>{{notification.heading}}</span></div>
  <div><span>{{notification.text}}</span></div>
</ng-template> -->