import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../../services/registration-service.service';
import { CitadelAutoCompleteComponent } from '@citadel/ds-controls';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignInService } from '../../services/sign-in-service';
import { CitadelNotificationService } from '@citadel/ds-core';


@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit, OnDestroy {
  @ViewChild('identification', { static: false }) identification: TemplateRef<any>;
  @ViewChild('otpchannel', { static: false }) otpchannel: TemplateRef<any>;
  @ViewChild('enterotp', { static: false }) enterotp: TemplateRef<any>;
  @ViewChild('password', { static: false }) password: TemplateRef<any>;
  @ViewChild('completeregistration', { static: false }) completeregistration: TemplateRef<any>;
  @ViewChild('country', { static: false }) country: CitadelAutoCompleteComponent;
  @ViewChild('otpInput', { static: false }) otpInput: ElementRef;

  selectedIndex = 0;
  steps: any = [
    { title: new BehaviorSubject<string>('Personal Details') },
    { title: new BehaviorSubject<string>('Password') },
    { title: new BehaviorSubject<string>('One-time-PIN') }
  ]

  currentTemplate: TemplateRef<any>;
  passwordForm: FormGroup;
  identityForm!: FormGroup;
  countries: any[] = [];
  tempcountries: any[] = [];
  extras: any;
  returnUrl: any;
  redirectUrl: any;
  appID: any;
  clientId: string;
  registerMessage: string;
  defaultErrorMessage: string = 'An error occured';
  clientName: string;
  requestId:string = '';
  passwordMessage: string;
  passwordStrength: string;
  confirmPassword: string;
  cellNumber: string = ''
  idNumber: string = '';
  emailAddress: string = '';
  countryCode: string = '+27';
  pwd: string;
  otpType: string;
  otpValue: string = '';
  score: number = 0;
  recaptchav3_token: string;
  termsAndConditions: boolean;
  otpSent: boolean;
  switchBullet: boolean;
  hasUpperAndLower: any = null;
  hasMinLength: any = null;
  hasNumber: any = null;
  hasSymbol: any = null;
  bar0: string;
  bar1: string;
  bar2: string;
  scoreColor: string;
  passwordMatch: any;
  initialPhone: any = { countryL: "91", dialingCode: "27", number: '' }
  isConfirmPasswordPristine: boolean = true;
  otpTypeLabels = [
    { name: "SMS", value: "SMS" },
    { name: "WhatsApp", value: "WhatsApp" }
  ]

  private unsubscribe$ = new Subject<void>();

  // convenience getter for easy access to form fields
  get idForm() { return this.identityForm.controls; }
  get pswForm() { return this.passwordForm.controls; }
  identityFormSubmitted: boolean = false;
  passwordFormSubmitted: boolean = false;
  showPinRequiredMsg: boolean = false;
  showChannelRequiredMsg: boolean = false;

  get otpTypeLabel(): string {
    return this.otpType === 'SMS' ? 'phone messaging app' : this.otpType === 'WhatsApp' ? 'WhatsApp' : '';
  }

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private registration: RegistrationService,
    private recaptchaV3Service: ReCaptchaV3Service, 
    private notify: CitadelNotificationService,
    private signInService: SignInService
  ) {
    if (router.getCurrentNavigation()) {
      this.extras = router.getCurrentNavigation().extras;
    }
    this.redirectUrl = this.signInService.registrationReturnUrl;
  }


  ngOnInit(): void {
    this.registration.getCountries().subscribe(countries => this.countries = countries);
    this.setPasswordForm();
    this.setIdentityForm();

    // setTimeout(() => {
    //   this.otpType = 'SMS';

    // this.currentTemplate = this.enterotp;
    // },1);
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setPasswordForm(): void {
    this.passwordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      })
  }


  checkInput(ev) {
    const control = this.passwordForm.controls['password'];
    this.isConfirmPasswordPristine = false;
    if (control.value === ev.target.value) {
      this.passwordMatch = true;
    }
    else {
      this.passwordMatch = false;
    }
  }


  setIdentityForm(): void {
    this.identityForm = this.formBuilder.group({
      idNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [this.initialPhone, Validators.required],
      termsAndConditions: [false, Validators.requiredTrue],
    })
  }

  updateIdentification(e, id): void {
    if (id === 'email') {
      e.target.value = e.target.value.replace(/ /g, '');
    }
  }

  onOtpChannelChange(e): void { 
    if (e && this.otpType) {
      this.showChannelRequiredMsg = false;
    } 
  }

  updateOtp(e): void {
    if (e) {
      this.showPinRequiredMsg = false;
    }
    this.otpValue = e;
  }

  updatePassword(e, password): void {
    this.checkPasswordStrength(e, password);
  }

  checkPasswordStrength(e, password): void {
    this.registration.validatePasswordStrength(password === 'password' ? e.target.value : this.pwd);
    this.passwordStrength = this.registration.passwordStrength;
    this.hasUpperAndLower = this.registration.hasUpperAndLower
    this.hasMinLength = this.registration.hasMinLength;
    this.hasNumber = this.registration.hasNumber;
    this.hasSymbol = this.registration.hasSymbol;
    this.scoreColor = this.registration.scoreColor;
    this.bar0 = this.registration.bar0;
    this.bar1 = this.registration.bar1;
    this.bar2 = this.registration.bar2;
    this.switchBullet = this.registration.switchBullet;
  }


  goToSendOTP(): void {
    this.passwordFormSubmitted = true;
    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    if (this.passwordMatch) {
      if (this.hasUpperAndLower && this.hasMinLength && this.hasNumber && this.hasSymbol && this.passwordMatch) {
        this.otpSent = false;
        this.selectedIndex = 2;
        this.currentTemplate = this.otpchannel;
      } else {
        this.notify.error("Please choose a strong password by following our guidelines on the screen.", "");
      }
    } else {
      this.notify.error("Passwords do not match.", "");
    }
  }

  goToEnterOTP(): void {
    if (!this.otpSent) {
      this.otpSent = true;
      if (this.otpType) {
        this.selectedIndex = 2;

        this.registration.sendOtp(this.otpType, this.requestId).subscribe(_ => {
          this.notify.success('OTP sent', "");
          this.currentTemplate = this.enterotp;
        }, _ => this.notify.error(this.defaultErrorMessage, ""));

      } else {
        this.showChannelRequiredMsg = true;
        this.notify.error('Please select an option before you continue', " ");
      }
    }
  }




  goToPassword(): any | null {
    this.identityFormSubmitted = true;
    // stop here if form is invalid
    if (this.identityForm.invalid) {
      this.notify.error('Please complete all fields.', '');
      return;
    }

    this.recaptchaV3Service.execute('identifyClient').subscribe((token) => {
      this.recaptchav3_token = token;
      this.getIdentityFormValues();
    },
      () => {
        this.notify.error('Error verifying the request (reCaptcha v3)', " ");
      });
  }

  resendOTP(): void {
    this.otpSent = false;
    this.currentTemplate = this.otpchannel;
  }

  getIdentityFormValues(): void {
    this.idNumber = this.identityForm.controls.idNumber.value;
    this.emailAddress = this.identityForm.controls.email.value;
    this.cellNumber = this.identityForm.controls.phoneNumber.value.number;
    this.countryCode = this.identityForm.controls.phoneNumber.value.dialingCode;
    this.country = this.identityForm.controls.phoneNumber.value.countryL;
    // if (!this.idNumber || !this.emailAddress || !this.cellNumber) {
    //   this.notify.error('Please complete all fields.', " ", {
    //     toastClass: "toast-error"
    //   });
    // } 
    // else {
    if (this.identityForm.controls.termsAndConditions.value) {
      this.identifyClient();
    } else {
      this.notify.error('Please agree to the terms and conditions before you continue.', " ");
    }

    // }

  }

  identifyClient(): void {

    this.registration.identifyClient(this.idNumber, this.emailAddress, this.cellNumber, this.countryCode.toString(), this.country.toString(), this.recaptchav3_token)
      .subscribe(details => {
        const res = details.split(';');
        if (res && res[1]) {
          this.clientName = res[1];
        }
        if (res && res.length > 1 && res[2]) {
          this.requestId = res[2];
        }
        if (res && res[0] && !res[0].match(/00000000-0000-0000-0000-000000000000/)) {
          if (res[0].match(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)) {
            this.clientId = res[0];
            this.clientId = this.clientId.replace(/\"/g, '');
            this.selectedIndex = 1;
            this.currentTemplate = this.password;

          } else {
            this.notify.error(res[0], " ");
          }
        } else if (res[0] && res[0].match(/00000000-0000-0000-0000-000000000000/)) {
          this.notify.error('The client could not be verified.', " ");
        } else {
          this.notify.error('The user already exists.', " ");
        }
      }, _error => {
        this.notify.error('The client could not be verified.', " ");
      });
  }

  completeRegistration(): void {
    if (!this.otpValue || this.otpValue.length < 4) {
      if (this.otpValue.length === 0) {
        this.showPinRequiredMsg = true;
      }
      this.notify.error('A 4-digit OTP is required', " ");
    } else {
      this.showPinRequiredMsg = false;
      this.saveUser();
    }
  }


  saveUser() {
    const password = this.passwordForm.value.password;
    this.registration.saveNewUser(this.otpValue, password, this.clientId, this.appID).subscribe(res => {
      this.registerMessage = res.toString();
      if (this.registerMessage.match(/success/)) {
        this.notify.success(this.registerMessage, " ");
        this.currentTemplate = this.completeregistration;
        setTimeout(() => {
          this.router.navigate(['sign-in'], { state: { returnUrl: this.redirectUrl, appID: this.appID, name: '', userName: this.emailAddress } });
        }, 3000);

      } else {
        this.notify.error(this.registerMessage, " ");
      }
    }, _ => {
      this.notify.error('An error occurred.', " ");
    });
  }

  handleKeyUp(e, step) {
    if (e.keyCode === 13) {
      switch (step) {
        case "goToEnterOTP":
          this.goToEnterOTP();
          break;
        case "completeRegistration":
          this.completeRegistration();
          break;
      }
    }
  }
}


