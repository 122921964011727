import { Component } from '@angular/core';

@Component({
  selector: 'app-invalid-email-page',
  templateUrl: './invalid-email-page.component.html',
  styleUrls: ['./invalid-email-page.component.scss']
})
export class InvalidEmailPageComponent {

}
