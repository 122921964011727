import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  
  passwordResetRequestId: string;
  passwordResetEmail: string;
  otpResetRequestId: string;
  signInEmail: string;
  signInPassword: string;
  registrationReturnUrl:string;
  signInReturnUrl:string;
  constructor( private client: HttpClient) { }




}
