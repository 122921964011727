import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SignInState } from '../../models/sign-in-state.model';
import { CitadelNotificationService } from '@citadel/ds-core';
import { RegistrationService } from '../../services/registration-service.service';
import { ApplicationService } from '../../services/application-service.service';

@Component({
  selector: 'app-password-entry-page',
  templateUrl: './password-entry-page.component.html',
  styleUrls: ['./password-entry-page.component.scss']
})
export class PasswordEntryPageComponent implements OnInit {
  signInForm = this.fb.group({
    password: ['', Validators.required],
    otp: ['', Validators.required]
  })
  routingState: { [k: string]: any; };
  credentials = {
    returnUrl: '',
    name: '',
    userName: '',
    authenticationTypeL: 0,
    clientDateTime: '',
    authRedirect: '',
  }
  appID;
  otpSent: boolean = false;
  otpEmailSent:boolean = false;
  otpType: string = '';
  valAuth:boolean = true;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private notify: CitadelNotificationService,
    private registration: RegistrationService,
    private application: ApplicationService) {
      const nav = router.getCurrentNavigation() || { extras: { state: {} }};
      const extras = nav.extras || {};
      this.routingState = extras.state || {};
    }

  ngOnInit(): void {
    const params = this.routingState;
    if (!params) {
      this.navigateHome();
    }
    this.credentials = {
      returnUrl: params['returnUrl'] || '',
      name: params['name'],
      userName: params['userName'],
      authenticationTypeL : params['authenticationTypeL'],
      clientDateTime : (new Date()).toISOString(),
      authRedirect: localStorage.getItem("auth_redirect") !== null ? localStorage.getItem("auth_redirect") : '',
    };
    if (this.credentials.authenticationTypeL){
      if (this.credentials.authenticationTypeL === 3){
        this.valAuth = false;
        this.signInForm.controls.password.setValue("N/A");
        this.otpEmailSent = true;
        this.sendOtpEmail();
      }
    }
    if (!this.credentials.returnUrl || !this.credentials.userName) {
      this.navigateHome();
    }
    if (this.routingState && this.routingState.appID) {
      this.appID = this.routingState.appID;
    }
    if (this.appID && this.appID === 'Citadel.Launcher.Msz') {
      this.application.setParagraph('MSZ');
    }
    else {
      this.application.setParagraph('Citazen');
    }
  }
  updatePasswordValidity(e) {
    this.signInForm.controls.password.patchValue(e.target.value);
  }
  updateOtpValidity(e) {
    this.signInForm.controls.otp.patchValue(e.target.value);
  }
  validatePassword() {
    if (this.signInForm.invalid) {
      return;
    }
    this.http.post<SignInState>(`/api/auth`, { ...this.credentials, password: this.signInForm.value.password, otp: parseInt(this.signInForm.value.otp) }, {
      headers: { 'Content-Type': 'application/json' },
      // withCredentials: true,
    }).subscribe(res => {
      if (res.message) {
        this.notify.error(res.message);
        return;
      }
      // console.log('Password validation result', res);
      if (res.isExpired) {
        this.router.navigate(['expired-password'], { state: this.credentials });
      } else {
        window.location.href = this.credentials.returnUrl;
      }
    }, _error => {

        if (!this.valAuth) {
          this.notify.error('Incorrect otp for this account');
        } else {
          this.notify.error('Incorrect password for this account');
        }
      // console.error('Password validation failed:', error);
      
    });
  }
  smsOtp() {
    this.otpType = 'sms';
    this.sendOtp();
  }
  emailOtp(){
    this.sendOtpEmail();
  }

  whatsappOtp() {
    this.otpType = 'whatsapp';
    this.sendOtp();
  }
  sendOtp() {
    this.registration.sendLoginOtp(this.otpType, this.credentials.userName).subscribe(_ => {
      if (this.otpSent) {
        this.notify.success('OTP resent');
      }
      else {
        this.notify.success('OTP sent');
      }
      this.otpSent = true;
    }, _error => {
      this.notify.error('Error sending OTP');
      this.otpSent = true;
    });
  }

sendOtpEmail(){
  this.registration.sendOtpEmail(this.credentials.userName).subscribe(_ => {

    if (this.otpEmailSent ){
      this.notify.success('OTP sent');
    }
    this.otpEmailSent = true;
  

  }, _error => {
    //this.notify.error('Error sending OTP');
    if (this.otpEmailSent ){
      this.notify.error('Error sending OTP');
    }
    this.otpEmailSent = true;
  });
}

  navigateHome() {
    this.router.navigate(['']);
  }
}
