import { Injectable } from '@angular/core';
import { CitadelWebService } from '@citadel/ds-core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  passwordStrength: string;
  mediumPasswordScore: number = 10;
  strongPasswordScore: number = 20;
  bar0: string;
  bar1: string;
  bar2: string;
  scoreColor: string;
  hasUpperAndLower: any = null;
  hasMinLength: any = null;
  hasNumber: any = null;
  hasSymbol: any = null;
  switchBullet: boolean;
  defaultBarColor: string = getComputedStyle(document.documentElement).getPropertyValue('--neutral-400');
  weakBarColor: string = getComputedStyle(document.documentElement).getPropertyValue('--palette-danger');
  mediumBarColor: string = getComputedStyle(document.documentElement).getPropertyValue('--palette-alternate-1');
  strongBarColor: string = getComputedStyle(document.documentElement).getPropertyValue('--status-success-default');

  constructor(private web: CitadelWebService, private client: HttpClient) { }



  validatePasswordStrength(password: string): any {
    const variations = {
      hasUpperAndLower: password.match(/^(?=.*[a-z])(?=.*[A-Z]).+$/),
      hasMinLength: password.length >= 8,
      hasNumber: password.match(/[0-9]+/),
      hasSymbol: password.match(/[$-/:-?{-~!&#*"^_\\@`\[\]]/g)
    };
    this.hasUpperAndLower = /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(password);
    this.hasMinLength = password.length >= 8;
    this.hasNumber = /[0-9]+/.test(password);
    this.hasSymbol = /[$-/:-?{-~!&#*"^_\\@`\[\]]/g.test(password);
    if (!this.hasUpperAndLower && !this.hasMinLength && !this.hasNumber && !this.hasSymbol) {
      this.switchBullet = true;
    }
    let score = 0;
    let variationCount = 0;
    for (const check of Object.keys(variations)) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    this.passwordStrength = this.calculatePasswordStrength(Math.trunc(score));
  }


  calculatePasswordStrength(score): string {
    if (score <= this.mediumPasswordScore) {
      this.setBarColors(1, this.weakBarColor);
      this.scoreColor = this.weakBarColor;
      return 'Weak';
    }
    if (score > this.mediumPasswordScore && score <= this.strongPasswordScore) {
      this.setBarColors(2, this.mediumBarColor);
      this.scoreColor = this.mediumBarColor;
      return 'Medium';
    }
    if (score > this.strongPasswordScore) {
      this.setBarColors(3, this.strongBarColor);
      this.scoreColor = this.strongBarColor;
      return 'Strong';
    }
  }



  setBarColors(count, col) {
    this.bar0 = this.defaultBarColor;
    this.bar1 = this.defaultBarColor;
    this.bar2 = this.defaultBarColor;
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }


  ConfirmedValidator(formGroup: FormGroup,controlName: string, matchingControlName: string) {
    return (formGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  getColorScheme(): any {
    let colorScheme: { background: 'light', sections: 'white', controls: 'light' }
    return colorScheme
  }
  getCountries(): Observable<any> {
    return this.client.get('/api/Registration/countries');
  }

  identifyClient(id: string, emailAddress: string, cellNumber: string, countryCode: string, country, token: string): Observable<any> {
    return this.client.post('/api/Registration/verify', { id, emailAddress, cellNumber, countryCode, country, token });
  }


  sendResetOtp(type, requestId): Observable<any> {
    return this.client.get(`/api/Registration/sendresetotp/${type}/${requestId}`);
  }

  sendOtp(type, requestId): Observable<any> {

      return this.client.get(`/api/Registration/sendotp/${type}/${requestId}`);

    
  }

  sendLoginOtp(type, clientUserName): Observable<any> {
    return this.client.get(`/api/Registration/sendloginotp/${type}/${clientUserName}`);
  }

  saveNewUser(otp, password, clientId, appId): Observable<any> {
    return this.client.post('/api/Registration/registeruser', {
      "otp": otp,
      "password": password,
      "clientId": clientId,
      "applicationId": appId
    });
  }

  verifyOTP(otp: string, clientId:string): Observable<any> {
    const result = this.client.get(`/api/Registration/verifyotp/${otp}/${clientId}`);
    return result;
  }

  sendOtpEmail(email): Observable<any> {
    return this.client.post('/Auth/sendotpemail', { "email": email });
  }

  addVerification(CellNumber: string, SmsMessage: string, CommunicationTypeL: number, ClientName: string): Observable<any> {
    const params: any = {};
    params.mobileNumber = CellNumber;
    params.smsMessage = SmsMessage;
    params.communicationType = CommunicationTypeL;
    params.clientName = ClientName;
    const result = this.web.post('communication/addVerification', params);
    return result;
  }


  verifyEmailAddress(email: string): Observable<any> {
    const params: any = { emailAddress: email };
    const result = this.web.post('communication/verifyEMailAddress', params);
    return result;

  }



}
