import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CitadelAutoCompleteComponent } from '@citadel/ds-controls';
import { BehaviorSubject, debounceTime, take } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RegistrationService } from '../../services/registration-service.service';
import { SignInService } from '../../services/sign-in-service';
import { SignInState } from '../../models/sign-in-state.model';
import { CitadelNotificationService, CitadelConfigurationService, CitadelErrorLoggingService } from '@citadel/ds-core';
import { EmailValidationResult } from '../../models/email-validation-result.model';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.scss']
})
export class SignInPageComponent implements OnInit {
  @ViewChild('emailstep', { static: false }) emailstep: TemplateRef<any>;
  @ViewChild('otpchannel', { static: false }) otpchannel: TemplateRef<any>;
  @ViewChild('enterotp', { static: false }) enterotp: TemplateRef<any>;
  @ViewChild('password', { static: false }) password: TemplateRef<any>;
  @ViewChild('country', { static: false }) country: CitadelAutoCompleteComponent;

  selectedIndex = 0;
  steps: any = [
    { title: new BehaviorSubject<string>('Email') },
    { title: new BehaviorSubject<string>('Password') },
    { title: new BehaviorSubject<string>('One-time-PIN') },
  ]
  currentTemplate: TemplateRef<any>;
  emailForm: FormGroup;
  passwordForm: FormGroup;
  countries: any[] = [];
  tempcountries: any[] = [];
  extras: any;
  returnUrl: any;
  redirectUrl: any;
  appID: any;
  clientId: string;
  registerMessage: string;
  clientName: string;
  passwordMessage: string;
  passwordStrength: string;
  confirmPassword: string;
  currentNumber?: any;
  cellNumber: string = ''
  pwd: string;
  otpType: string;
  otpValue: string = '';
  score: number = 0;
  recaptchav3_token: string;
  termsAndConditions: boolean;
  registerError: boolean;
  otpSent: boolean;
  resetRequestId: string = undefined;
  emailAddress: string = '';
  hasSymbol: any = null;
  defaultErrorMessage: string = 'An error occured. Please try again';
  resendOtpText: string = 'Didn\'t receive the OTP?';
  bar0: string;
  bar1: string;
  bar2: string;

  otpTypeLabels = [
    { name: "SMS", value: "SMS" },
    { name: "WhatsApp", value: "WhatsApp" }
  ]


  protected value = {
    countryL: 0,
    dialingCode: 0,
    number: '',
  };

  credentials = {
    returnUrl: '',
    userName: '',
    authenticationTypeL: 0,
    requestID:''
  }
  valAuth: boolean = true;


  get otpTypeLabel(): string {
    return this.otpType === 'SMS' ? 'phone messaging app' : this.otpType === 'WhatsApp' ? 'WhatsApp' : '';
  }

  get scoreColor(): string {
    if (this.score > 0 && this.score <= 50) {
      return '#DF0303';
    }
    if (this.score > 50 && this.score <= 80) {
      return '#DF820D';
    }
    if (this.score > 80 && this.score <= 100) {
      return '#0DAC45';
    }
  }

  signInForm: FormGroup;

  // convenience getter for easy access to form fields
  get usernameForm() { return this.emailForm.controls; }
  get pswForm() { return this.passwordForm.controls; }
  emailFormSubmitted: boolean = false;
  passwordFormSubmitted: boolean = false;
  showPinRequiredMsg: boolean = false;
  showChannelRequiredMsg: boolean = false;

  impersonate = '';
  routingState: { [k: string]: any; };
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
    private registration: RegistrationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private notify: CitadelNotificationService,
    private signInService: SignInService,
    private config: CitadelConfigurationService,
    private errorLogging: CitadelErrorLoggingService,
  ) {
    const nav = router.getCurrentNavigation() || { extras: { state: {} } };
    const extras = nav.extras || {};
    this.routingState = extras.state || {};
  }

  ngOnInit() {
    this.setPasswordForm();
    this.setIdentityForm();
    const params = this.routingState;

    this.returnUrl = this.routingState ? this.routingState['returnUrl'] : undefined;
    this.signInService.signInReturnUrl = this.returnUrl;
    if (this.returnUrl) {
      this.appID = this.loadFromUrl(this.returnUrl, 'client_id');
      this.impersonate = this.loadFromUrl(this.returnUrl, 'impersonate');
      // this.maintenanceParams.setApplicationID(this.appID);
    } else {
      this.navigateToApp();
    }

    this.credentials = {
      returnUrl: params['returnUrl'] || '',
      userName: params['userName'],
      authenticationTypeL: params['authenticationTypeL'],
      requestID:''
    };

    this.signInService.registrationReturnUrl = this.credentials.returnUrl;

  }


  private navigateToApp() {
    this.config.getGlobalSettingsLoaded().pipe(take(1)).subscribe(settings => {
      const rerouteAddress = settings.rerouteAddress;
      if (!!rerouteAddress) {
        window.location.href = rerouteAddress;
      } else {
        // This can only be used once the auth service runs under the main application
        //this.router.navigate(['..'], { relativeTo: this.route });
      }
    });
  }

  loadFromUrl(returnUrl: string, paramName: string): string {
    const regexResult = RegExp(`${paramName}=([\\.\\w-]+)`).exec(this.returnUrl);
    // const regexResult = RegExp(/client_id=([\.\w]+)&/).exec(this.returnUrl);
    if (regexResult && Array.isArray(regexResult) && regexResult.length >= 2 && typeof regexResult[1] === 'string') {
      return regexResult[1];
    }
    return '';
  }

  setPasswordForm(): void {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required]]
    });
    this.attachUiLogging('password', this.passwordForm);
  }

  setIdentityForm(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.attachUiLogging('email', this.emailForm);
  }

  private attachUiLogging(name: string, form: FormGroup) {
    form.statusChanges.subscribe(status => {
      if (status === 'INVALID') {
        this.logError(form, name);
      }
    });
  }

  private logError(form: FormGroup<any>, name: string) {
    const errors = Object.keys(form.errors || {}).map(key => `${key}: ${form.errors[key]}`).join(', ');
    const values = Object.keys(form.value || {}).map(key => `${key}: ${key === 'password' ? form.value[key]?.length || 0 : form.value[key]}`).join(', ');
    this.errorLogging.logError('auth.input', `${name} Errors: ${errors}\nValue: ${values}`);
  }

  beforeValidateEmail() {
    this.recaptchaV3Service.execute('validateEmail').subscribe((token) => {
      this.recaptchav3_token = token;
      this.validateEmail();
    });
  }

  validateEmail() {
    if (!this.emailForm.value.email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/)) {
      return;
    }
    this.http.post<string>(`/api/auth/forgotpassword`,
      { email: this.emailForm.value.email, returnUrl: this.returnUrl, recaptchaToken: this.recaptchav3_token },
      { headers: { 'Content-Type': 'application/json' }, withCredentials: true, }).subscribe(res => {

        if (res['passwordResetRequestId'].match(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)
          && res['otpRequestId'].match(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/)) {

          this.signInService.passwordResetRequestId = res['passwordResetRequestId'];
          this.signInService.passwordResetEmail = this.emailForm.value.email;
          this.signInService.otpResetRequestId = res['otpRequestId'];
          //this.signInService.otpResetRequestId = this.credentials.requestID;
          this.router.navigateByUrl('/forgot-password', { state: { returnUrl: window.location.href } })
        }
      }, _error => {
        this.notify.error(this.defaultErrorMessage, "");
      });
  }

  confirmEmail() {
    if (!this.emailForm.value.email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/)) {
      return;
    }
    this.http.get<EmailValidationResult>(`/api/auth/${this.emailForm.value.email}`).subscribe(res => {
      this.credentials.userName = res.userName;
      this.credentials.authenticationTypeL = res.authenticationTypeL;
      this.credentials.requestID = res.requestID;

      if (res.authenticationTypeL > 0) { // AD Account
        if (localStorage) {
          localStorage.setItem('Citadel.Auth.Service.AccountType', 'CitadelActiveDirectory');
        }
        window.location.href = `/external/challenge?userName=${this.credentials.userName}&impersonate=${this.impersonate}&returnUrl=${encodeURI(this.returnUrl)}`;
      } else {
        this.signInService.signInEmail = this.emailAddress;
        this.currentTemplate = this.password;
        this.selectedIndex = 1;
      }
    }, _error => {

      if (_error.error.message) {
        this.notify.error(_error.error.message, "");
      } else {
        this.notify.error(_error.message, "");
      }

      this.selectedIndex = 0;
    });
  }


  updateIdentification(e, id) {
    if (id === 'email') {
      e.target.value = e.target.value.replace(/ /g, '');
    }
  }



  onOtpChannelChange(e): void {
    if (e && this.otpType) {
      this.showChannelRequiredMsg = false;
    }
  }

  updateOtp(e): void {
    if (e) {
      this.showPinRequiredMsg = false;
    }
    this.otpValue = e;
  }
  validateOtp(otp): any | null {
    if (!otp || otp.length < 4) {

    }


  }
  resendOTP(): void {
    this.otpSent = false;
    this.currentTemplate = this.otpchannel;
  }


  confirmPasswordValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (control?.value !== matchingControl?.value) {
        matchingControl?.setErrors({ confirmPassword: true });
        return { confirmPassword: true }
      } else {
        matchingControl?.setErrors(null);
        return null;
      }
    }

  }



  getStrength(): string {
    if (this.score > 0 && this.score <= 50) {
      // this.setBarColors(1, '#DF0303');
      return 'Weak';
    }
    if (this.score > 50 && this.score <= 80) {
      // this.setBarColors(2, '#DF820D');
      return 'Average';
    }
    if (this.score > 80 && this.score <= 100) {
      // this.setBarColors(3, '#0DAC45');
      return 'Strong';
    }
  }

  goToSendOTP() {
    this.passwordFormSubmitted = true;
    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      this.logError(this.passwordForm, 'password');
      this.notify.error('Please enter your password.', " ");
      return;
    }

    this.recaptchaV3Service.execute('identifyClient').subscribe((token) => {
      this.recaptchav3_token = token;
      this.selectedIndex = 2;
      this.currentTemplate = this.otpchannel;
    },
      () => {
        this.notify.error('Error verifying the request (reCaptcha v3)', " ");
      });

  }

  goToEnterOTP() {
    this.sendOtp();
  }

  goToPassword() {
    this.emailFormSubmitted = true;
    // stop here if form is invalid
    if (this.emailForm.invalid) {
      this.logError(this.emailForm, 'email');
      this.notify.error('Please enter your email address', '');
      return;
    }
    this.emailAddress = this.emailForm.controls.email.value;
    this.confirmEmail();
  }

  signIn() {

    if (!this.otpValue || this.otpValue.length < 4) {
      if (this.otpValue.length === 0) {
        this.showPinRequiredMsg = true;
      }
      this.notify.error('A 4-digit OTP is required', " ");
    } else {
      this.showPinRequiredMsg = false;
      this.credentials.returnUrl = this.signInService.signInReturnUrl;
      this.http.post<SignInState>(`/api/auth`, { ...this.credentials, password: this.passwordForm.value.password, otp: parseInt(this.otpValue) }, {
        headers: { 'Content-Type': 'application/json' },
      }).subscribe({
          next: _ => window.location.href = this.credentials.returnUrl,
          error: error => {
            console.error('Signin error', error);
            const err = error?.error || { };
            if (!err.isSignedIn && !err.passwordValid) {
              this.notify.error(err.message || 'There was an error signing you in. Please try again.', "");
              this.selectedIndex = 1;
              this.otpSent = false;
              this.currentTemplate = this.password;
            } else if (!err.isSignedIn && err.passwordValid && !err.otpValid) {
              this.notify.error(err.message, "");
              this.otpSent = false;
              this.resendOtpText = "Resend OTP"
              this.selectedIndex = 2;
              this.currentTemplate = this.otpchannel;
            } else if (err.isExpired) {
              this.notify.error(err.message, "");
              this.resetSignin();
              this.router.navigate(['expired-password'], { state: this.credentials });
            } else {
              this.notify.error('There was an error signing you in. Please try again.', "");
              this.selectedIndex = 0;
              this.resetSignin();
            }
          }
        });
    }
  }

  private resetSignin() {
    this.emailForm.reset();
    this.passwordForm.reset();
    this.otpSent = false;
    this.currentTemplate = this.emailstep;
  }

  sendOtp(): void {
    if (!this.otpSent) {
      if (this.otpType) {
        this.registration.sendOtp(this.otpType, this.credentials.requestID)
          .pipe(debounceTime(2000))
          .subscribe({
            next: _ => {
              this.notify.success('OTP sent', "");
              this.selectedIndex = 2;
              this.currentTemplate = this.enterotp;
              this.otpSent = true;
            }, error: _ => this.notify.error(this.defaultErrorMessage, "")
          });
      } else {
        this.showChannelRequiredMsg = true;
        this.notify.error('Please select an option before you continue', " ");
      }
    }
  }


  handleKeyUp(e, step) {
    if (e.keyCode === 13) {
      switch (step) {
        case "sendOtp":
          this.sendOtp();
          break;
        case "signIn":
          this.signIn();
          break;
      }
    }
  }
}
