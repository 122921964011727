import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CitadelStylesModule } from '@citadel/ds-styles';
import { CitadelCoreModule, CitadelGlobalErrorHandler } from '@citadel/ds-core';
import { CitadelControlsModule, CitadelToasterComponent, OverlayModule } from '@citadel/ds-controls'; 
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SignInPageComponent } from './pages/sign-in-page/sign-in-page.component';
import { PasswordEntryPageComponent } from './pages/password-entry-page/password-entry-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { InvalidEmailPageComponent } from './pages/invalid-email-page/invalid-email-page.component';
import { ToastrModule } from 'ngx-toastr';
import { SignInRedirectorComponent } from './pages/sign-in-redirector/sign-in-redirector.component';
import { SignInCallbackComponent } from './pages/sign-in-callback/sign-in-callback.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { RecaptchaModule , RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { SignOutPageComponent } from './pages/sign-out-page/sign-out-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';

import { AppRoutingModule } from './app-routing.module'; 
//import { NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

// const cookieConfig:NgcCookieConsentConfig = {
//   cookie: {
//     domain: window.location.hostname
//   },
//   position: "bottom-right",
//   theme: "edgeless",
//   palette: {

//   },
//   layout: 'my-custom-layout',
//   layouts: {
//     "my-custom-layout": `
//     <span class="cookieContainerCls">
//         {{messageContent}}
//         <div class="compButtonContainerCls">
//             <div class="compButtonCls">
//                 {{compliance}}
//             </div>
//         </div>
//     <span>`
//   },
//   elements:{
//     "messageContent": `
//     <div class="cookieMessageCls" >
//       <div class="headerCls"><img src='assets/bell-ringing.svg'/> Cookies notice</div>
//       <div class="messageCls">{{message}}
//       <a class="readMoreCls" href="{{href}}" target="_blank">{{link}}</a>
//       </div>
    
//     </div>
//     `,
//   },
//   "content": {
//      "message": "Our website uses cookies to ensure you<br> get the best experience. If you<br> continue to use the site, we assume you <br>consent to the use of cookies on this<br> site.",
//      dismiss: "Dismiss",
//      "link": "Read more",
//      "href": "https://www.citadel.co.za/privacy-notice/",
//   }
// };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignInPageComponent,
    PasswordEntryPageComponent,
    RegistrationPageComponent,
    InvalidEmailPageComponent,
    SignInRedirectorComponent,
    SignInCallbackComponent,
    ErrorPageComponent,
    SignOutPageComponent,
    ForgotPasswordPageComponent, 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CitadelStylesModule,
    CitadelCoreModule.forRoot({ isProduction: environment.production}),
    CitadelControlsModule,
    ToastrModule.forRoot({
      toastComponent: CitadelToasterComponent,      
      positionClass: 'toast-top-center'
    }),
    RecaptchaModule,
    RecaptchaV3Module,
    OverlayModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: CitadelGlobalErrorHandler },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdQ8OMZAAAAAMTAnp6wCCDhZNI8w0anfUirIuPE' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
